<template>
  <div class="popUp">
    <el-dialog
        :visible.sync="visible"
        center>
      <template slot="title">
        <div class="upWT">设置背景</div>
        <div class="close">
        </div>
      </template>
    <el-form :model="inputList" class="demo-form-inline">
      <div class="el-upload__tip">请上传首页的背景图片，图片不大于5M，尺寸1920x316</div>
      <el-upload
          class="avatar-uploader"
          list-type="picture-card"
          ref="uploader"
          :auto-upload="true"
          :headers="{ token: $store.state.userInfo.token }"
          :action="constant.UPLOAD_URL + '/file/upload'"
          :show-file-list="true"
          :on-success="handleUploadSuccess"
          :limit="1"
          :on-exceed="imgExceed"
          :before-upload="beforeUpload"
        >
        <i slot="default" class="el-icon-plus"></i>
        <!-- <img :src="imageUrl" v-show="imageUrl" alt="" style="width: 64px; height: 64px"> -->
      </el-upload>

      <el-form-item style="text-align:center">
        <el-button type="primary" class="btn"
                   :class="{'active':isActive=true}"
                   @click="submit">提交</el-button>
        <el-button type="primary" class="btn" @click="close">取消</el-button>
      </el-form-item>

      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "setbackground",
  data() {
    return {
      visible: false,
      dialogFormVisible: false,
      number: 0,
      imageUrl: '',
      isActive: true,
      inputList:{},
      uploadedProof:[],
      prevOrderId: 0,
  };
},
  methods: {
  handleUploadSuccess(res) {
    console.log('handleUploadSuccess', res)
    this.imageUrl = res.data.filename
  },

  hide(){
    this.visible = false
  },
  submit(){
    if( !this.imageUrl){
      this.$notice({message: '请等待上传图片'}).isShow()
      return
    }
    const self = this
    const data = {
      schoolId: this.$store.state.userInfo.schoolId,
      bgImage: this.imageUrl
    }
    console.log('背景图片data',data);
    if (!this.imageUrl) {
      this.$notice({message: '请先上传背景图片'}).isShow()
      return
    }

    this.api.school.updateBgimgeBySchoolId(data).then(res => {
      console.log('updateCostOrderInfo', res)
      this.$store.dispatch("getSchoolInfo");
      if (res.flag) {
        self.$notice({type: 'success', message: res.message}).isShow()
        self.$emit('submit', this.imageUrl)
        self.hide();
        self.visible = false
      } else {
        self.$notice({message: res.message}).isShow()
      }
    })
  },

  tabClass: function (index) {
    this.number = index;
  },
    show(schoolId = 0,) {
      if (schoolId !== this.prevOrderId){
        this.uploadedProof = []
        this.imageUrl = ''
      }

      this.visible = true
    },
  verify(){
    this.visible = false;
    this.$emit('verify', this.visible)
  },
  close(){
    this.visible = false;
    this.$emit('close', this.visible)
  },
  //上传图片超过限制数量
  imgExceed(){
    this.$notice({message: '只能上传一张图片'}).isShow();
  },
  //图片上传前校验图片大小
  beforeUpload(file){
    let size = file.size / 1024 / 1024;
    if(size > 5){
      this.$notice({message: '上传图片不能大于5M'}).isShow();
      return false;
    }
  }
},
  props: {},
  watch: {},
}
</script>

<style lang="scss" scoped>

/deep/ .el-upload__tip{
  font-size: 16px;
  font-weight: 400;
  color: #888888!important;
  margin-bottom: 20px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 148px;
  margin-bottom: 20px;
}
/deep/  .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 69px;
  height: 59px;
  line-height: 148px;
  text-align: center;
  display: block;
  background: url(../../../assets/img/schoolIndex/Icon_UpD_Pic_Bg.png) no-repeat;
  background-size: 100% 100%;
  margin: 25px auto 10px auto;
}
/deep/ .avatar {
  //width: 178px;
  height: 148px;
  display: block;
}
/deep/ .demo-form-inline .el-upload-list--picture-card .el-upload-list__item{
  width: 100%;
}
.icon-text{
  font-size: 16px;
  font-weight: 400;
  color: #DDDDDD;
}

.btn{
  width: 100px;
  height: 38px;
  //background: none;
  //border:none;
  border: 1px solid #BA3037;
  border-radius: 6px;
  background: #fff;
  color: #BA3037;
  font-size: 18px;
  margin:  0 25px;
}
.active{
  background: linear-gradient(232deg, #B93037, #EF525B);
  box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
  color: #fff;
  border: 1px solid transparent;
}

/deep/ .el-dialog--center {
  width: 550px;
  .el-form-item {
    margin-bottom: 20px;
  }
}

/deep/ .el-input__inner {
  border: 1px solid #ddd;
  border-radius: 10px;
}

/deep/ .el-dialog__header {
  padding: 0;
  position: relative;
}

/deep/ .el-dialog__headerbtn {
  top: 0.15rem;
  opacity: 0;
}

.upWT {
  height: 50px;
  line-height: 50px;
  background: #B93138;
  color: #fff;
}

.close {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 15px;
  height: 15px;
  background: url(../../../assets/img/course/Icon_tc_close.png) no-repeat;
  background-size: cover;
}
</style>