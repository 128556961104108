<template>
  <div class="attron">
    <el-dialog
        :visible.sync="visible"
        @close="close"
        center>
      <template slot="title">
        <div class="upWT">转让园长</div>
        <div class="close">
        </div>
      </template>

        <el-form
            ref="makeOverFrom"
            :rules="rules"
            style="padding:0.2rem"
            :model="form"
            label-width="2rem"
        >
          <el-form-item class="form_item_block" prop="username" label="新联系人姓名">
            <el-input v-model="form.username"></el-input>
            <input type="text" style="display:block;width:0px;height:0px;opacity:0;" />
          </el-form-item>
          <el-form-item class="form_item_block" prop="password" label="新密码">
            <input type="password" style="display:block;width:0px;height:0px;opacity:0;" />
            <el-input type="password" v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item
              class="form_item_block"
              prop="newPassword"
              label="再次确认密码"
          >
            <el-input type="password" v-model="form.newPassword"></el-input>
          </el-form-item>
          <el-form-item class="form_item_block" prop="mobile" label="新手机号">
            <el-input
                v-model="form.mobile"
                onkeyup="this.value = this.value.replace(/[^\d.]/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item
              class="form_item_block code_block"
              prop="code"
              label="验证码"
          >
            <el-input
                v-model="form.code"
                maxlength="4"
                onkeyup="this.value = this.value.replace(/[^\d.]/g,'')"
            ></el-input>
            <el-button class="code" @click="getCode">{{
                PhoneCodeTime ? PhoneCodeTime + 's' : '获取验证码'
              }}</el-button>
          </el-form-item>
          <el-form-item class="form_item_block" prop="email" label="新邮箱">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       @click="submit"
                       :class="{'active':isActive=true}"
                       class="btn">提交</el-button>
            <el-button class="btn" @click="close">取消</el-button>
          </el-form-item>
        </el-form>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'makeOver',
  components: {},
  data() {
    var validateName = (rule, value, callback) =>{
      if(!value){
        callback(new Error('请输入新联系人姓名'))
      }else{
        if (!this.common.textNumberCheck(value,25)) {
          callback(new Error("请输入正确格式新联系人姓名"));
        }else{
          callback();
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if(!this.common.validateLength(value,16)){
          callback(new Error("请输入正确格式密码"));
        }else{
          if (this.form.newPassword !== '') {
            this.$refs.makeOverFrom.validateField('newPassword')
          }
          callback()
        }

      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var validateMobile = (rule, value, callback) => {
      if(!value){
        callback(new Error('请输入新手机号'))
      }else{
        if (!this.common.isMobile(value)) {
          callback(new Error('请输入正确格式新手机号'))
        } else {
          callback()
        }
      }
    }
    var validateEmail = (rule, value, callback)=>{
      if(!value){
        callback(new Error("请输入新邮箱"));
      }else{
        if(!this.common.validateLength(value,25)){
          callback(new Error("请输入正确格式新邮箱"));
        }else{
          if (this.common.isEmail(value)) {
            callback();
          } else {
            callback(new Error("请输入正确格式新邮箱"));
          }
        }
      }
    }

    return {
      visible: true, // 弹窗是否显示
      form: {
        username: '',
        password: '',
        newPassword: '',
        mobile: '',
        code: '',
        email: '',
      },
      PhoneCodeTime: 0,
      rules: {
        username: [
          { required: true, validator: validateName, trigger: 'blur' },
        ],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        newPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
        mobile: [
          { required: true, validator: validateMobile, trigger: 'blur' },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        email: [
          { required: true, validator: validateEmail, trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur'],
          },
        ],
      },
    }
  },
  created() {},
  mounted() {},

  methods: {

    close(){
      this.visible = false;
      this.$emit('attronClose', this.visible)
    },

    getCode() {
      if (!this.PhoneCodeTime) {
        if (!this.form.mobile) {
          this.$notice({
            message: '请输入联系人手机号'
          }).isShow()
          return
        }
        if (!this.common.isMobile(this.form.mobile)) {
          this.$notice({
            message: '请输入正确格式的手机号码'
          }).isShow()
          return
        }

        this.PhoneCodeTime = 180
        let timer = setInterval(() => {
          this.PhoneCodeTime = this.PhoneCodeTime - 1
          if (this.PhoneCodeTime == 0) {
            this.PhoneCodeTime = ''
            clearInterval(timer)
          }
        }, 1000)
        this.api.user
            .changePassSend({
              phone: this.form.mobile,
            })
            .then((res) => {
              if (res.flag) {
                this.$notice({
                  type: 'success',
                  message: '发送成功'
                }).isShow()
              } else {
                this.$notice({
                  message: res.message
                }).isShow()
              }
            })
      } else {
        this.$notice({
          message: '请在倒计时结束后再次发送验证码'
        }).isShow()
      }
    },
    submit() {
      this.$refs.makeOverFrom.validate((valid) => {
        if (valid) {
          let data = {
            username: this.form.username,
            password: this.form.password,
            mobile: this.form.mobile,
            code: this.form.code,
            email: this.form.email,
          }
          this.api.admin.changeDirector(data).then((res) => {
            if (res.flag) {
              this.$notice({
                type: 'success',
                message: '转让成功'
              }).isShow()
              this.$store.dispatch('out', this)
            } else {
              this.$notice({
                message: res.message
              }).isShow()
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.code{
  height: 38px;
  position: absolute;
  right: 60px;
  top: 2px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  color: #B93138;
  background: center;
}

.btn {
  width: 100px;
  height: 38px;
  //background: none;
  //border:none;
  border: 1px solid #BA3037;
  border-radius: 6px;
  background: #fff;
  color: #BA3037;
  font-size: 18px;
  margin:  0 25px;
}

.active {
  background: linear-gradient(232deg, #B93037, #EF525B);
  box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
  color: #fff;
  border: 1px solid transparent;
}


/deep/ .el-dialog--center {
 width: 600px;

  .el-form-item {
    margin-bottom: 20px;
  }
}

/deep/ .el-form-item__label {
  color: #333;
  font-size: 16px;
  width: 150px !important;
}

/deep/ .el-input {
  width: 320px;
  display: inline;
}
/deep/ .el-input__inner {
  width: 320px!important;
  border: 1px solid #ddd;
  border-radius: 10px;
  height: 40px;
}
/deep/ .el-form-item__content {
  margin-left: 150px !important;
}

/deep/ .el-dialog__header {
  padding: 0;
  position: relative;
}

/deep/ .el-dialog__headerbtn {
  top: 0.15rem;
  opacity: 0;
}

.upWT {
  height: 50px;
  line-height: 50px;
  background: #B93138;
  color: #fff;
}
.close {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 15px;
  height: 15px;
  background: url(../../../assets/img/course/Icon_tc_close.png) no-repeat;
  background-size: cover;
}
</style>
