<template>
  <div class="product">
    <div class="graybg">
      <div class="whiteBg">
        <div class="product-tit">
          <div :class="currentId == item.id ? 'active' : ''" v-for="item in list" :key="item.id" @click="change(item)">{{item.name}}</div>
        </div>
      </div>
      
    </div>
    
    <Account v-if="currentId == 1"></Account>
    <Message v-if="currentId == 2"></Message>
    <Letter v-if="currentId == 3"></Letter>
  </div>
</template>
<script>
import Account from './account.vue'
import Message from '../schoolAdmin/message.vue'
import Letter from '../schoolAdmin/letter.vue'

export default {
  components:{
    Account,
    Message,
    Letter
  },
  data() {
    return {
      list:[
          {
              name:'账号设置',
              id:1,
          },
          {
              name:'消息',
              id:2,
          },
          {
              name:'站内信',
              id:3,
          },
      ],
      currentId:1,
    }
  },
  methods: {
    change(data){
      this.currentId = data.id;
    }
  },
  created() {
    
  },
  mounted() {
    
  }
}
</script>
<style lang="scss" scoped>
.product{
  .graybg{
    background: #F1F1F1;
  }
  .whiteBg{
    background: #FFFFFF;
    width: 1434px;
    margin:0 auto;
    padding-top:40px;
    padding-bottom: 30px;
  }
    .product-tit{
      
        margin:0 auto;
        width: 420px;
        height: 48px;
        line-height: 48px;
        background: #F1F1F1;
        border-radius: 24px;
        font-size: 22px;
        color: #333333;
        @include flex(row,space-between,center);

        >div{
            flex: 1;
            text-align: center;
            cursor: pointer;
        }

        .active{
            width: 140px;
            height: 48px;
            background: #395DA4;
            box-shadow: 0px 3px 20px 0px rgba(57, 93, 164, 0.39);
            border-radius: 24px;
            font-weight: bold;
            color: #FFFFFF;
            font-size: 24px;
        }
    }
}
</style>
