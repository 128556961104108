<template>
  <div class="schoolBody">
    <div class="schoolIndex">
      <div class="navList">
        <h4>账户设置</h4>
        <div class="uploadList">
          <button
            type="button"
            class="uploadBtn cursorStyle"
            @click="backgroundBox"
          >
            <i class="iconList set"></i>
            设置背景
          </button>
          <button
            type="button"
            class="uploadBtn cursorStyle"
            @click="attronBox"
          >
            <i class="iconList transfer"></i>
            转让园长
          </button>
          <button
            type="button"
            class="uploadBtn cursorStyle"
            @click="passWordBox"
          >
            <i class="iconList modify"></i>
            修改密码
          </button>
          <button
            type="button"
            class="uploadBtn quit cursorStyle"
            @click="logout"
          >
            退出登录
          </button>
        </div>
      </div>
      <div class="schoolInfo">
        <div class="information">
          <h5 class="inforTitle">
            <span></span><em>个人信息</em><i class="schoolmaster">园长</i>
          </h5>
          <div class="inforName">
            <div class="inforText fl">
              <label>姓名</label>
              <p>{{ schoolList.contacts }}</p>
            </div>
            <div class="inforText fl">
              <label>手机:</label>
              <p>{{ schoolList.contactsMobile }}</p>
            </div>
          </div>
        </div>
        <!-- <div class="information">
          <h5 class="inforTitle"><span></span><em>金币账户</em></h5>
          <div class="goldcoin-content">
            <h2 class="goldcoin-content-title">账户余额</h2>
            <h2 class="goldcoin-content-count">
              <span>{{ goldAmount }}</span
              >金币
            </h2>
            <div class="goldcoin-content-btn">
              <div class="recharge-btn" @click="bindRecharge">立即充值</div>
              <div class="purchase-btn" @click="bindPurchase">购买套餐</div>
            </div>
          </div>
        </div> -->
        <div class="information">
          <h5 class="inforTitle">
            <span></span>
            园所信息
            <i class="editor" v-if="!edit" @click="editoInput"><b></b>编辑</i>
          </h5>
          <div class="inforName">
            <div class="inforText boxWidth fl">
              <p><label>园所名称:</label>{{ schoolList.schoolName }}</p>
            </div>
            <div class="inforText boxWidth fl">
              <p><label>园所备案号:</label>{{ schoolList.sn }}</p>
            </div>
            <div class="inforText boxWidth">
              <p>
                <label>社会统一信用代码或办学许可证:</label
                >{{ schoolList.xydm }}
              </p>
            </div>
            <div class="inforText boxWidth fl">
              <p><label>所在城市:</label>{{ schoolList.city }}</p>
            </div>
            <div class="inforText boxWidth fl">
              <p><label>详细地址:</label>{{ schoolList.address }}</p>
            </div>
            <div class="inforText boxWidth fl">
              <p><label>园所类型:</label>{{ schoolType[schoolList.type] }}</p>
            </div>
            <div class="inforText boxWidth fl">
              <p><label>园所幼儿人数:</label>{{ schoolList.number }}</p>
            </div>
            <div style="clear: both"></div>
            <div class="inforText" style="overflow: hidden">
              <label class="fl">教学理念:</label>
              <el-input
                v-if="edit"
                style="display: inline"
                placeholder="请输入内容"
                v-model="schoolList.intro"
                maxlength="128"
                show-word-limit
                clearable
              >
              </el-input>
              <p v-else style="width: 7rem">{{ schoolList.intro }}</p>
            </div>
            <div class="inforText" style="overflow: hidden">
              <label>活动场地平米数:</label>
              <div
                class="inputWidth"
                v-if="edit"
                style="position: relative; width: 3.68rem"
              >
                <el-input-number
                  placeholder="请输入内容"
                  v-model="schoolList.square"
                  clearable
                  :min="0"
                  type="number"
                >
                </el-input-number>
                <span
                  class="suffix"
                  style="position: absolute; right: 0.1rem; top: 0.03rem"
                  >㎡</span
                >
              </div>
              <p v-else>{{ schoolList.square }}</p>
            </div>
            <div class="inforText" style="overflow: hidden">
              <label>运动器材:</label>
              <el-input
                class="inputWidth"
                placeholder="请输入内容"
                v-model="schoolList.equment"
                clearable
                v-if="edit"
              >
              </el-input>
              <p v-else>{{ schoolList.equment }}</p>
            </div>
            <div class="inforText" style="overflow: hidden">
              <label>园所标语:</label>
              <el-input
                class="inputWidth"
                placeholder="请输入内容"
                v-model="schoolList.slogan"
                clearable
                maxlength="40"
                v-if="edit"
              >
              </el-input>
              <p v-else>{{ schoolList.slogan }}</p>
            </div>
            <div
              :class="['inforText', { fl: !edit }]"
              style="overflow: hidden; margin-right: 1.8rem"
            >
              <label>园所logo:</label>
              <div v-if="edit">
                <el-upload
                  list-type="picture-card"
                  :auto-upload="true"
                  :headers="{ token: $store.state.userInfo.token }"
                  :action="constant.UPLOAD_URL + '/file/upload'"
                  :file-list="schoolLogoList"
                  :on-success="onSchoolLogoUploaded"
                  :on-exceed="schoolLogoExceed"
                  :limit="1"
                  class="topPlus"
                >
                  <!--                  <img v-if="constant.URL + schoolLogo" :src="constant.URL + schoolLogo" class="avatar">-->
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
              <div v-else class="licensePic">
                <span class="defautLogo" v-show="!schoolLogo"></span>
                <img
                  :src="constant.URL + schoolLogo"
                  alt=""
                  v-show="schoolLogo"
                />
              </div>
            </div>

            <div class="inforText leftPic fl" style="overflow: hidden">
              <label>上传营业执照或办学许可证:</label>
              <div slot="tip" class="el-upload__tip" v-if="edit">
                只能上传jpg/png文件，且不超过500kb
              </div>
              <div v-if="edit">
                <el-upload
                  :action="constant.UPLOAD_URL + '/file/upload'"
                  list-type="picture-card"
                  :auto-upload="true"
                  :on-success="onSchoolBusinessUploaded"
                  :headers="{ token: $store.state.userInfo.token }"
                  :file-list="schoolbusinessList"
                  :limit="10"
                  :on-exceed="schoolbusinessExceed"
                  :on-remove="removeSchoolbusinessList"
                >
                  <i class="el-icon-plus" style="line-height: normal"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible" class="licensePic">
                  <img
                    width="100%"
                    :src="constant.URL + i"
                    v-for="(i, index) in schoolbusiness"
                    :key="index"
                    alt=""
                  />
                </el-dialog>
              </div>
              <div v-else style="margin-top: 0.1rem">
                <span class="defaultPic" v-if="!schoolbusiness"></span>
                <img
                  v-else
                  :src="constant.URL + i"
                  alt=""
                  v-for="(i, index) in schoolbusiness"
                  :key="index"
                />
              </div>
              <div style="color: #ba3037; font-size: 18px; margin-top: 10px">
                {{
                  schoolList.status == 0
                    ? "未授权"
                    : schoolList.status == 2
                    ? schoolList.rejectReaso
                    : schoolList.status == 44
                    ? "已冻结"
                    : ""
                }}
              </div>
              <div v-if="edit">
                <el-button
                  size="small"
                  type="success"
                  class="uploadBtnClear"
                  :class="{ active: !isActive }"
                  @click="submitSchool"
                  >保存
                </el-button>
                <el-button
                  slot="trigger"
                  style="margin-left: 10px"
                  size="small"
                  type="primary"
                  class="uploadBtnClear"
                  @click="closeEdit"
                  >取消
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--页面设置-->
      <div class="information">
        <h5 class="inforTitle">
          <span></span>页面设置
          <i
            class="editor"
            v-show="!isEditTeacher"
            @click="isEditTeacher = true"
            ><b></b>编辑</i
          >
        </h5>
        <div class="inforName">
          <el-form
            :model="formPage"
            status-icon
            ref="formPage"
            label-width="100px"
            class="demo-ruleForm"
          >
            <div class="teacherList">
              <label>师资力量:</label>
              <div
                class="teachers fl"
                v-if="!cacheNewSchoolFacultyList.length && !isEditTeacher"
              >
                <div class="teachersHeader fl">
                  <div>
                    <img
                      style="height: 1.38rem"
                      src="../../assets/img/school/teacher_tx_null.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="teacherInfo">
                  <div class="tackList">
                    <div class="name editable fl" style="margin-right: 0.2rem">
                      <span>教师姓名</span>
                    </div>
                  </div>
                  <div class="intro editable">
                    <p>暂无数据</p>
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="teachers fl"
                  v-for="(t, i) in newSchoolFacultyList"
                  :key="`teacher-${i}`"
                  :class="{ radius: isEditTeacher }"
                >
                  <i
                    :class="{ picIcon: isEditTeacher }"
                    @click="deleteFacultyList(i)"
                  ></i>
                  <div class="teachersHeader fl">
                    <div v-if="isEditTeacher">
                      <el-upload
                        prop="photo"
                        list-type="picture-card"
                        :auto-upload="true"
                        :headers="{ token: $store.state.userInfo.token }"
                        :action="constant.UPLOAD_URL + '/file/upload'"
                        :show-file-list="false"
                        :on-success="(res) => onTeacherAvatarUploaded(res, i)"
                        :limit="1"
                        class="topPlus"
                      >
                        <i
                          slot="default"
                          class="el-icon-plus"
                          v-show="!t.photo"
                        ></i>
                        <img
                          :src="constant.URL + t.photo"
                          v-show="t.photo"
                          alt=""
                        />
                      </el-upload>
                    </div>
                    <div v-else>
                      <img
                        style="height: 1.38rem"
                        :src="constant.URL + t.photo"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="teacherInfo">
                    <div class="tackList">
                      <div
                        class="name"
                        :class="{ fl: isEditTeacher, editable: isEditTeacher }"
                        style="margin-right: 0.2rem"
                      >
                        <span
                          style="width: 2.75rem; display: block; height: 0.3rem"
                          v-show="!isEditTeacher"
                          >{{ t.name }}</span
                        >
                        <el-input
                          v-model="t.name"
                          placeholder="姓名"
                          maxlength="50"
                          v-show="isEditTeacher"
                          size="mini"
                          style="margin-right: 0.2rem"
                        ></el-input>
                      </div>
                      <div
                        :class="[
                          'sex',
                          'fl',
                          {
                            selectWidth: isEditTeacher,
                            editable: isEditTeacher,
                          },
                        ]"
                      >
                        <span style="width: auto" v-show="!isEditTeacher">{{
                          gender[t.sex]
                        }}</span>
                        <el-select
                          v-model="t.sexName"
                          placeholder="性别"
                          v-show="isEditTeacher"
                          @change="
                            (val) => {
                              t.sex = val;
                            }
                          "
                          size="mini"
                        >
                          <el-option label="男" value="1"></el-option>
                          <el-option label="女" value="2"></el-option>
                        </el-select>
                      </div>
                      <div class="education editable selectWidth degree fl">
                        <span v-show="!isEditTeacher">{{
                          degreeList[t.degree]
                        }}</span>
                        <el-select
                          v-model="t.degreeName"
                          placeholder="学历"
                          v-show="isEditTeacher"
                          @change="
                            (val) => {
                              t.degree = val;
                            }
                          "
                          size="mini"
                        >
                          <el-option label="博士" value="0"></el-option>
                          <el-option label="硕士" value="1"></el-option>
                          <el-option label="本科" value="2"></el-option>
                          <el-option label="专科" value="3"></el-option>
                          <el-option label="高中" value="4"></el-option>
                          <el-option label="初中" value="5"></el-option>
                        </el-select>
                      </div>
                    </div>
                    <div :class="['intro', { editable: isEditTeacher }]">
                      <p style="width: 3rem" v-show="!isEditTeacher">
                        {{ t.intro }}
                      </p>
                      <el-input
                        v-show="isEditTeacher"
                        type="textarea"
                        :rows="2"
                        placeholder="简介"
                        maxlength="128"
                        show-word-limit
                        v-model="t.intro"
                      >
                      </el-input>
                    </div>
                  </div>
                </div>
                <div
                  class="teachers fl"
                  v-show="isEditTeacher && newSchoolFacultyList.length < 4"
                >
                  <div class="uploadElem" @click="addEmptyTeacher">
                    <i data-v-4498f7b0="" class="el-icon-plus"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="teacherList" v-show="teachersShow">
              <label>教学实力:</label>
              <div
                class="teachers teachersTeaching fl"
                v-if="!cacheNewSchoolCreditList.length && !isEditTeacher"
              >
                <div class="teachersHeader widthPic fl">
                  <div>
                    <img src="../../assets/img/school/run_nullpic.png" alt="" />
                  </div>
                </div>
                <div class="teacherInfo teachingWidth">
                  <div class="tackList">
                    <div class="name">
                      <span>暂无数据</span>
                    </div>
                  </div>
                  <div class="intro">
                    <p>暂无数据</p>
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="teachers teachersTeaching fl"
                  v-for="(t, i) in newSchoolCreditList"
                  :key="`teacher-${i}`"
                  :class="{ radius: isEditTeacher }"
                >
                  <i
                    :class="{ picIcon: isEditTeacher }"
                    @click="deleteTeaching(i)"
                  ></i>
                  <div class="teachersHeader widthPic fl">
                    <div v-if="isEditTeacher">
                      <el-upload
                        list-type="picture-card"
                        :auto-upload="true"
                        :headers="{ token: $store.state.userInfo.token }"
                        :action="constant.UPLOAD_URL + '/file/upload'"
                        :show-file-list="false"
                        :on-success="
                          (res) => onNewSchoolCreditListUploaded(res, i)
                        "
                        :limit="1"
                        class="teachingPic"
                      >
                        <img
                          :src="constant.URL + t.imageUrl"
                          v-if="t.imageUrl"
                          alt=""
                        />
                        <i slot="default" class="el-icon-plus" v-else></i>
                      </el-upload>
                    </div>
                    <div v-else>
                      <img :src="constant.URL + t.imageUrl" alt="" />
                    </div>
                  </div>
                  <div class="teacherInfo teachingWidth">
                    <div class="tackList">
                      <div class="name">
                        <span
                          style="width: 1.5rem; height: 0.3rem; display: block"
                          v-show="!isEditTeacher"
                          >{{ t.imageTital }}</span
                        >
                        <el-input
                          maxlength="32"
                          show-word-limit
                          v-model="t.imageTital"
                          placeholder="请输入标题"
                          v-show="isEditTeacher"
                          size="mini"
                        ></el-input>
                      </div>
                    </div>
                    <div class="intro">
                      <p style="width: 2.5rem" v-show="!isEditTeacher">
                        {{ t.imageDesc }}
                      </p>
                      <el-input
                        v-show="isEditTeacher"
                        type="textarea"
                        :rows="2"
                        placeholder="请输入简介"
                        maxlength="36"
                        show-word-limit
                        v-model="t.imageDesc"
                      >
                      </el-input>
                    </div>
                  </div>
                </div>
                <div class="teachers fl" v-show="isEditTeacher">
                  <div class="uploadElem widthPic" @click="addTeachersTeaching">
                    <i data-v-4498f7b0="" class="el-icon-plus"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="teacherList">
              <label>校园风采:</label>
              <div
                class="teachers schoolyard fl"
                v-if="!cacheNewSchoolAnnexList.length && !isEditTeacher"
              >
                <div class="teachersHeader widthPic fl">
                  <div>
                    <img src="../../assets/img/school/banner_null.png" />
                  </div>
                </div>
                <div class="teacherInfo teachingWidth">
                  <div class="tackList">
                    <div class="name">
                      <span>暂无数据</span>
                    </div>
                  </div>
                  <div class="intro">
                    <p>暂无数据</p>
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="teachers schoolyard fl"
                  v-for="(t, i) in newSchoolAnnexList"
                  :key="`schoolyardList-t-${i}`"
                  :class="{ radius: isEditTeacher }"
                >
                  <i
                    :class="{ picIcon: isEditTeacher }"
                    @click="deleteAnnex(i)"
                  ></i>

                  <div class="teachersHeader widthPic fl">
                    <div v-if="isEditTeacher">
                      <el-upload
                        list-type="picture-card"
                        :auto-upload="true"
                        :headers="{ token: $store.state.userInfo.token }"
                        :action="constant.UPLOAD_URL + '/file/upload'"
                        :show-file-list="false"
                        :on-success="
                          (res) => onNewSchoolAnnexListUploaded(res, i)
                        "
                        :limit="1"
                        class="teachingPic"
                      >
                        <i
                          slot="default"
                          class="el-icon-plus"
                          v-show="!t.imageUrl"
                        ></i>
                        <img
                          :src="constant.URL + t.imageUrl"
                          v-show="t.imageUrl"
                          alt=""
                        />
                      </el-upload>
                    </div>
                    <div v-else>
                      <img :src="constant.URL + t.imageUrl" />
                    </div>
                  </div>
                  <div class="teacherInfo teachingWidth">
                    <div class="tackList">
                      <div class="name">
                        <span
                          style="width: 1.15rem; height: 0.3rem; display: block"
                          v-show="!isEditTeacher"
                          >{{ t.imageTital }}</span
                        >
                        <el-input
                          maxlength="32"
                          show-word-limit
                          v-model="t.imageTital"
                          placeholder="请输入标题"
                          v-show="isEditTeacher"
                          size="mini"
                        ></el-input>
                      </div>
                    </div>
                    <div class="intro">
                      <p style="width: 2.5rem" v-show="!isEditTeacher">
                        {{ t.imageDesc }}
                      </p>
                      <el-input
                        v-show="isEditTeacher"
                        type="textarea"
                        :rows="2"
                        placeholder="请输入简介"
                        maxlength="128"
                        show-word-limit
                        v-model="t.imageDesc"
                      >
                      </el-input>
                    </div>
                  </div>
                </div>
                <div
                  class="teachers fl"
                  v-show="isEditTeacher && newSchoolAnnexList.length < 4"
                >
                  <div class="uploadElem" @click="addSchoolAnnexList">
                    <i data-v-4498f7b0="" class="el-icon-plus"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <div class="inforBtn" v-if="isEditTeacher">
          <el-button
            size="small"
            type="success"
            class="uploadBtnClear"
            @click="submitPage"
            :class="{ active: !isActive }"
            >保存
          </el-button>
          <el-button
            slot="trigger"
            style="margin-left: 10px"
            size="small"
            type="primary"
            class="uploadBtnClear"
            @click="cancelEdit"
            >取消
          </el-button>
        </div>
      </div>

      <setbackground ref="setbackground"></setbackground>

      <attron
        ref="attron"
        v-if="attronShow"
        @attronClose="attronClose"
      ></attron>

      <amend-password
        ref="amend"
        v-if="visibleShow"
        @visibleColse="visibleColse"
      ></amend-password>
    </div>
    <GoldRecharge
      :status="goldStatus"
      :goldCNum="goldNum"
      @close="close"
      @goldPay="bindPay"
      @bindAgreement="bindToAgree"
    />
    <Wxcode
      :status="codeStatus"
      :price="codePrice"
      :orderId="orderId"
      :codeUrl="codeUrl"
      @close="closeWxcode"
    ></Wxcode>
    <goldAgreementPop
      :status="AgreementStatus"
      @close="closeAgreement"
    ></goldAgreementPop>
  </div>
</template>
<script>
import Attron from "@/views/user/schoolIndex/attron";
import Setbackground from "@/views/user/schoolIndex/setBackground";
import AmendPassword from "@/views/user/schoolIndex/amendPassword";
// 金币充值弹出框
import GoldRecharge from "@/views/product/components/gold-recharge";
// 金币协议
import goldAgreementPop from "@/views/product/components/goldAgreementPop.vue";
import Wxcode from "@/views/product/components/goldwxcode.vue";
// import api from "@/api";

export default {
  components: {
    AmendPassword,
    Setbackground,
    Attron,
    GoldRecharge,
    Wxcode,
    goldAgreementPop,
  },
  data() {
    return {
      AgreementStatus: false, // 金币协议
      goldNum: 0, // 充值默认金币数
      goldAmount: 0, // 金币数
      goldStatus: false, // 控制金币弹出框
      codeStatus: false, // 控制微信支付二维码弹出框
      codeUrl: "", // 二维码路径
      orderId: "", // 订单
      codePrice: 0, // 价格
      input: "",
      number: "6",
      visibleShow: false,
      attronShow: false,
      genders: { 1: "男", 2: "女" },
      degreeList: {
        0: "博士",
        1: "硕士",
        2: "本科",
        3: "专科",
        4: "高中",
        5: "初中",
      },
      schoolType: { 0: "公立", 1: "私立" },

      schoolLogoUrl: "",

      teachers: [
        { img: "", name: "张三", gender: "1", edu: "1", desc: "是个好人" },
      ],
      teachersTeaching: [
        { img: "", name: "首都文明单位", desc: "简介文案简介" },
      ],
      schoolyardList: [{ img: "", name: "首都文明单位", desc: "简介文案简介" }],
      schoolLogo: "",
      phont: "",
      dialogVisible: false,
      disabled: false,
      isActive: false,
      edit: false,
      isEditTeacher: false,
      gender: { 1: "男", 2: "女" },
      //师资力量
      newSchoolFacultyList: [],
      //校园风采formPage schoolAnnexList
      newSchoolAnnexList: [],
      //教学实力
      newSchoolCreditList: [],
      //缓存师资力量
      cacheNewSchoolFacultyList: [],
      //缓存校园风采formPage schoolAnnexList
      cacheNewSchoolAnnexList: [],
      //缓存教学实力
      cacheNewSchoolCreditList: [],
      //校园信息 查询
      schoolList: {},

      schoolbusiness: [],

      //校园页面设置

      formPage: {},
      intefile: [],
      schoolLogoimg: "",
      photoAvatar: "",
      imageUrl: "",
      teachersShow: true,
      isTeacher: false, //是否为老师账号登陆
      schoolLogoList: [], //学校logo列表
      schoolbusinessList: [], //营业执照或办学许可证列表
      facultyIds: [], //删除师资力量id
      creditIds: [], //删除教学实力id
      annexIds: [], //删除校园风采id
    };
  },
  methods: {
    backgroundBox() {
      this.$refs.setbackground.show();
    },

    attronBox() {
      this.attronShow = true;
    },

    passWordBox() {
      this.visibleShow = true;
    },

    visibleColse() {
      this.visibleShow = false;
    },

    attronClose() {
      this.attronShow = false;
    },

    //学校信息 查询
    schoolInfo() {
      let that = this;
      var data = {};
      var schoolId = that.$store.state.userInfo.schoolId;
      this.api.school.getatteFindSchool(data, schoolId).then((res) => {
        this.$store.dispatch("getSchoolInfo");
        console.log("res学校状态", res.data.status);
        that.schoolList = res.data;
        if (res.data.intefile) {
          that.schoolbusiness = res.data.intefile.split(",");
          this.schoolbusinessList = that.schoolbusiness.map((item) => {
            return {
              url: this.constant.URL + item,
              imgUrl: item,
            };
          });
        } else {
          that.schoolbusiness = res.data.intefile;
        }
        that.intefile = res.data.intefile.split(",");
        that.schoolLogo = res.data.schoolLogo;
        // that.schoolLogoList = [{"url":this.constant.URL + res.data.schoolLogo}];

        if (res.data.schoolLogo) {
          that.schoolLogoList = [
            { url: this.constant.URL + res.data.schoolLogo },
          ];
        }
      });
    },
    //移除营业执照或办学许可证
    removeSchoolbusinessList(res) {
      let len = null;

      if (this.schoolbusinessList.length < 2) {
        this.$notice({
          message: "图片不能为空",
        }).isShow();
        return false;
      }
      this.schoolbusinessList.forEach((item, index) => {
        if (item.imgUrl === res.imgUrl) {
          len = index;
        }
      });
      this.$delete(this.schoolbusinessList, len);
    },
    //学校信息 提交
    submitSchool() {
      let that = this;
      let intefile = this.schoolbusinessList
        .map((item) => {
          return item.imgUrl;
        })
        .join(",");

      let data = {
        number: that.schoolList.number,
        equment: that.schoolList.equment,
        slogan: that.schoolList.slogan,
        square: that.schoolList.square,
        schoolLogo: that.schoolLogo,
        intefile: intefile,
        intro: that.schoolList.intro,
        status: that.schoolList.status,
      };
      console.log("data", data);
      var schoolId = that.$store.state.userInfo.schoolId;
      this.api.school.submitAtteSchool(data, schoolId).then((res) => {
        if (res.flag) {
          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
          that.schoolInfo();
        } else {
          return this.$notice({
            message: res.message,
          }).isShow();
        }
        that.edit = false;
      });
    },

    //师资力量图片上传
    onTeacherAvatarUploaded(res, i) {
      //this.newSchoolFacultyList[i].photo = res.data.url
      this.newSchoolFacultyList[i].photo = res.data.filename;
    },
    //教学实力图片上传
    onNewSchoolCreditListUploaded(res, i) {
      //this.newSchoolCreditList[i].imageUrl = res.data.url
      //value 重新赋的值 K是具体要改数据
      if (this.newSchoolCreditList[i]) {
        this.newSchoolCreditList[i].imageUrl = res.data.filename;
      } else {
        this.$set(this.newSchoolCreditList, i, {
          imageUrl: res.data.filename,
        });
      }
    },

    //校园风采图片上传
    onNewSchoolAnnexListUploaded(res, i) {
      // this.newSchoolAnnexList[i].imageUrl = res.data.url;

      if (this.newSchoolAnnexList[i]) {
        this.newSchoolAnnexList[i].imageUrl = res.data.filename;
      } else {
        this.$set(this.newSchoolAnnexList, i, {
          imageUrl: res.data.filename,
        });
      }
    },

    //学校log图片上传
    onSchoolLogoUploaded(res) {
      this.schoolLogo = res.data.filename;
    },
    //学校logo文件超出个数限制
    schoolLogoExceed() {
      this.$notice({
        message: "只能上传一张图片",
      }).isShow();
    },

    onSchoolBusinessUploaded(res) {
      this.intefile.push(res.data.filename);
      this.schoolbusinessList.push({
        url: this.constant.URL + res.data.filename,
        imgUrl: res.data.filename,
      });
    },
    //上传营业执照或办学许可证文件超出个数限制
    schoolbusinessExceed() {
      this.$notice({
        message: "只能上传十张图片",
      }).isShow();
    },
    // handlePictureCardPreview(file) {
    //   console.log("234234234234",file)
    //   this.dialogImageUrl = file.filename.split(',');
    //   this.dialogVisible = true;
    // },
    //添加师资力量
    addEmptyTeacher() {
      if (this.checkFaculty()) {
        this.newSchoolFacultyList.push(this.newEmpty("faculty"));
      }
    },

    //删除师资力量
    deleteFacultyList(i) {
      this.facultyIds.push(this.newSchoolFacultyList[i].id);
      this.newSchoolFacultyList.splice(i, 1);
    },

    //添加教学实力
    addTeachersTeaching() {
      // let data = {
      //   "imageDesc": "",  //证件描述信息
      //   "imageTital": "", //证件标题
      //   "imageUrl": "", //证件图片URL
      // }
      if (this.checkCredit()) {
        this.newSchoolCreditList.push(this.newEmpty("credit"));
      }
    },
    //删除教学实力
    deleteTeaching(i) {
      this.creditIds.push(this.newSchoolCreditList[i].id);
      this.newSchoolCreditList.splice(i, 1);
    },

    //添加校园风采
    addSchoolAnnexList() {
      if (this.checkAnnex()) {
        this.newSchoolAnnexList.push(this.newEmpty("annex"));
      }
    },
    //删除教学实力
    deleteAnnex(i) {
      this.annexIds.push(this.newSchoolAnnexList[i].id);
      this.newSchoolAnnexList.splice(i, 1);
    },

    //校园页面设置 查询
    getPage() {
      let that = this;
      let data = {
        schoolId: that.$store.state.userInfo.schoolId,
      };
      that.newSchoolFacultyList = [];
      that.cacheNewSchoolFacultyList = [];
      this.api.school.findSchoolShow(data).then((res) => {
        //师资力量
        res.data.schoolFacultyList.forEach((e) => {
          if (
            !e.name ||
            !e.sex ||
            (!e.degree && e.degree != 0) ||
            !e.photo ||
            !e.intro ||
            !e.intro
          ) {
            return;
          }
          let intro = e.intro.slice(0, 128);
          let data = {
            name: e.name,
            sex: e.sex,
            degree: e.degree,
            photo: e.photo,
            intro: intro,
            degreeName: this.degreeList[e.degree],
            sexName: this.gender[e.sex],
            id: e.id,
          };
          that.newSchoolFacultyList.push(data);
          that.cacheNewSchoolFacultyList.push(data);
        });

        //教学实力
        that.newSchoolCreditList = [];
        that.cacheNewSchoolCreditList = [];
        res.data.schoolCreditList.forEach((e) => {
          if (!e.imageUrl || !e.imageTital || !e.imageDesc) {
            return;
          }
          let imageDesc = e.imageDesc.slice(0, 36);
          let data = {
            imageUrl: e.imageUrl,
            imageTital: e.imageTital,
            imageDesc: imageDesc,
            id: e.id,
          };
          that.newSchoolCreditList.push(data);
          that.cacheNewSchoolCreditList.push(data);
        });

        //校园风采
        that.newSchoolAnnexList = [];
        that.cacheNewSchoolAnnexList = [];
        res.data.schoolAnnexList.forEach((e) => {
          if (!e.imageUrl || !e.imageDesc) {
            return;
          }
          let imageDesc = e.imageDesc.slice(0, 128);
          let data = {
            imageDesc: imageDesc,
            imageUrl: e.imageUrl,
            imageTital: e.imageTital,
            id: e.id,
          };
          that.newSchoolAnnexList.push(data);
          that.cacheNewSchoolAnnexList.push(data);
        });
      });
    },

    //校园页面设置 提交
    submitPage() {
      let that = this;

      //检查是否有空着的师资力量
      if (!this.checkFaculty() || !this.checkCredit() || !this.checkAnnex()) {
        return;
      }

      var schoolId = that.$store.state.userInfo.schoolId;
      this.newSchoolFacultyList.forEach((e) => {
        e.sex = parseInt(e.sex);
        e.degree = parseInt(e.degree);
      });
      let data = {
        newSchoolFacultyList: this.newSchoolFacultyList, //师资力量
        newSchoolCreditList: this.newSchoolCreditList, //教学实力
        newSchoolAnnexList: this.newSchoolAnnexList, //校园风采
      };
      if (this.facultyIds.length) data.facultyIds = this.facultyIds.join(",");
      if (this.creditIds.length) data.creditIds = this.creditIds.join(",");
      if (this.annexIds.length) data.annexIds = this.annexIds.join(",");

      this.api.school.saveSchoolShow(data, schoolId).then((res) => {
        if (res.flag) {
          that.getPage();
          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
          that.isEditTeacher = false;

          this.cacheNewSchoolFacultyList = JSON.parse(
            JSON.stringify(this.newSchoolFacultyList)
          ); //师资力量
          this.cacheNewSchoolCreditList = JSON.parse(
            JSON.stringify(this.newSchoolCreditList)
          ); //教学实力
          this.cacheNewSchoolAnnexList = JSON.parse(
            JSON.stringify(this.newSchoolAnnexList)
          ); //校园风采
          this.facultyIds = []; //删除师资力量id
          this.creditIds = []; //删除教学实力id
          this.annexIds = []; //删除校园风采id
        } else {
          return this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    cancelEdit() {
      //校园设置取消
      this.isEditTeacher = false;
      this.newSchoolFacultyList = JSON.parse(
        JSON.stringify(this.cacheNewSchoolFacultyList)
      ); //师资力量
      this.newSchoolCreditList = JSON.parse(
        JSON.stringify(this.cacheNewSchoolCreditList)
      ); //教学实力
      this.newSchoolAnnexList = JSON.parse(
        JSON.stringify(this.cacheNewSchoolAnnexList)
      ); //校园风采
      this.facultyIds = []; //删除师资力量id
      this.creditIds = []; //删除教学实力id
      this.annexIds = []; //删除校园风采id
    },
    editoInput() {
      this.edit = true;
    },

    closeEdit() {
      this.edit = false;
      this.schoolInfo();
    },
    //退出
    logout() {
      // this.$store.dispatch("out")
      this.$store.dispatch("out", this);
      // let that = this
      // api.admin.logout().then((res) => {
      //
      //   console.log('res', res);
      //   window.sessionStorage.clear();
      //   that.$router.push({
      //     path: "/home",
      //   });
      // })
    },
    /**
     * 生产空白数据
     * @param {String} type 类型：credit教学实力、faculty师资力量、annex校园风采
     * @returns {Object}
     */
    newEmpty(type = "") {
      const factory = {
        // 生产一个空的教学实力
        credit() {
          return {
            imageDesc: "", //证件描述信息
            imageTital: "", //证件标题
            imageUrl: "", //证件图片URL
            // "id":"",
          };
        },

        // 生产一个空的师资力量
        faculty() {
          return {
            degree: "", //教师学历:0=博士,1=硕士,2=本科,3=专科,4=高中,5=初中
            name: "", //姓名
            photo: "", //图片
            sex: "", //学生性别:0=未知,1=男,2=女
            intro: "", //简介
            degreeName: "", //学历名称
            sexName: "", //性别名称
            // "id":""
          };
        },

        // 生产一个空的校园风采
        annex() {
          return {
            imageDesc: "", //证件描述信息
            imageTital: "", //证件标题
            imageUrl: "", //证件图片URL
            // "id":""
          };
        },
      };

      if (!Object.keys(factory).includes(type)) {
        return {};
      }

      return factory[type]();
    },

    /**
     * 检查现有的列表
     * @param {String} type 类型：credit教学实力、faculty师资力量、annex校园风采
     * @param {Array} exists 现有的列表
     * @returns {boolean} 没问题返回true，有问题返回false
     */
    check(type = "", exists = []) {
      const empty = this.newEmpty(type); // 先获取一个空的数据
      for (let i = 0; i < exists.length; i++) {
        for (let k in exists[i]) {
          if (exists[i][k] === empty[k]) {
            return false;
          }
        }
      }
      return true;
    },

    // 检查师资力量是否有空着没填的
    checkFaculty() {
      if (!this.check("faculty", this.newSchoolFacultyList)) {
        this.$notice({ message: "您还有没填写的师资力量" }).isShow();
        return false;
      }
      return true;
    },

    checkCredit() {
      if (!this.check("credit", this.newSchoolCreditList)) {
        this.$notice({ message: "您还有没填写的教学实力" }).isShow();
        return false;
      }
      return true;
    },

    checkAnnex() {
      if (!this.check("annex", this.newSchoolAnnexList)) {
        this.$notice({ message: "您还有没填写的校园风采" }).isShow();
        return false;
      }
      return true;
    },
    // 获取当前用户金币数
    getGoldInfo() {
      let data = {
        userId: this.$store.state.userInfo.userId,
      };
      this.api.goldcoin.getGoldInfo(data).then((res) => {
        console.log(res, "我还有多少金币");
        if (res.code == 20000) {
          this.goldAmount = res.data.goldAmount;
        }
      });
    },
    // 金币立即充值
    bindRecharge() {
      console.log("我要立即充值");
      this.goldStatus = true;
      this.goldNum = 0;
    },
    bindPay(val, goldAmount) {
      this.codePrice = Number(goldAmount);
      let data = {
        userId: this.$store.state.userInfo.userId,
        price: Number(goldAmount),
      };
      if (val == 1) {
        this.api.goldcoin.createNative(data).then((res) => {
          console.log(res, "微信支付");
          if (res.code == 20000) {
            this.close();
            this.codeStatus = true;
            this.codeUrl = res.data.code_url;
            this.orderId = res.data.orderNo;
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        });
      } else {
        this.api.goldcoin.offineRecharge(data).then((res) => {
          console.log(res, "线下支付");
          if (res.code == 20000) {
            this.close();
            this.$notice({
              type: "success",
              message: "请及时上传凭证",
            }).isShow();
            this.$router.push({
              path: "/product?type=order",
            });
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        });
      }
    },
    // 关闭弹出框
    close() {
      this.goldStatus = false;
    },
    closeWxcode() {
      this.codeStatus = false;
      this.getGoldInfo();
      this.goldNum = 0;
    },
    // 弹起协议窗口
    bindToAgree() {
      this.AgreementStatus = true;
    },
    // 关闭协议
    closeAgreement() {
      this.AgreementStatus = false;
    },
    // 套餐立即购买
    bindPurchase() {
      this.$router.push({
        path: "/product",
        query: {
          type: "class",
        },
      });
    },
  },
  created() {
    this.isTeacher = !!this.$store.state.userInfo.teacherId;
  },
  mounted() {
    this.getPage();
    this.schoolInfo();
    this.getGoldInfo(); // 获取金币
  },
};
</script>
<style lang="scss" scoped>
.schoolBody {
  width: 100%;
  background: #f1f1f1;
}
/deep/.el-input--suffix .el-input__inner {
  padding-right: 90px;
}

.defautLogo {
  display: block;
  width: 100px;
  height: 80px;
  background: url(../../assets/img/schoolIndex/Pic_Ks_UpDL.png) no-repeat;
  background-size: 100% 100%;
}
.defaultPic {
  display: block;
  width: 80px;
  height: 80px;
  background: url(../../assets/img/schoolIndex/Pic_Ks_UpDL.png) no-repeat;
  background-size: 100% 100%;
}

/deep/.el-input-number__decrease {
  display: none;
}
/deep/.el-input-number__increase {
  display: none;
}

.inforBtn {
  margin-bottom: 30px;
}
.cursorStyle {
  cursor: pointer;
}
/deep/.degree .el-input .el-input__inner {
  width: 89px;
}
/deep/ .el-input--mini .el-input__icon {
  line-height: inherit;
}

.inforName .boxWidth {
  width: 400px;

  p {
    display: inline-block;
    color: #333333;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    width: 400px;
  }
}

/deep/ .el-textarea {
  width: 300px;
  position: relative;
}

/deep/ .el-input__count {
  position: absolute;
  right: 10px;
}

/deep/ .teachingWidth {
  .el-input__inner {
    width: 300px;
    height: 40px;
    padding-right: 70px;
  }

  .el-textarea__inner {
    width: 300px;
    resize: none;
    margin-top: 10px;
    height: 83px;
  }
}

/deep/ .el-upload--picture-card i {
  line-height: 140px;
}

/deep/ .topPlus {
  height: 140px;

  .el-upload {
    height: 140px;
  }
}

.teachingPic {
  height: 80px;

  .el-upload {
    height: 80px;
  }
}

/deep/ .editable {
  width: 98px;

  .el-input {
    float: left;
    width: 98px;
    height: 40px;

    .el-input__inner {
      float: left;
      width: 98px;
      height: 40px;
    }
  }

  .el-textarea__inner {
    width: 300px;
    resize: none;
    margin-top: 10px;
    height: 83px;
  }
}

/deep/ .selectWidth {
  width: 80px;

  .el-select {
    float: left;
    width: 80px;
  }

  .el-input {
    float: left;
    width: 80px;

    .el-input__inner {
      width: 80px;
      padding-right: 20px;
    }
  }
}

//上传学校logo
///deep/.topPlus{
//  margin-top: 12px;
//}
.fl {
  float: left;
}

.fr {
  float: right;
}

.none {
  display: none;
}

.schoolIndex {
  width: 1434px;
  background: #fff;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
}

.navList {
  overflow: hidden;

  h4 {
    float: left;
    font-size: 24px;
    font-weight: bold;
    color: #ba3037;
    margin-top: 30px;
  }

  .uploadList {
    float: right;
    margin-top: 27px;

    .uploadBtn {
      background: #ffffff;
      border: 1px solid #2e3c6b;
      border-radius: 17px;
      margin-left: 7px;
      color: #36467b;
      font-size: 18px;
      width: 120px;
      height: 34px;
      //background:url(../../assets/img/schoolIndex/Btn_Bg_H_Blue.png) no-repeat;
      &:hover {
        //background:url(../../assets/img/schoolIndex/Btn_Bg_H_Blue.png) no-repeat top center;
        //background-size: 100% 100%;
        //width: 148px;
        //height: 63px;
        border: 1px solid transparent;
        color: #fff;
        background: #36467b;
        //padding: 0;
        .iconList {
          display: inline-block;
          width: 16px;
          height: 16px;
        }

        .set {
          background: url(../../assets/img/schoolIndex/Icon_Btn_SetBg_H.png)
            no-repeat;
          background-size: 100% 100%;
        }

        .transfer {
          background: url(../../assets/img/schoolIndex/Icon_Btn_ZrYz_H.png)
            no-repeat;
          background-size: 100% 100%;
        }

        .modify {
          background: url(../../assets/img/schoolIndex/Icon_Btn_XgMm_H.png)
            no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .quit {
      border: 1px solid #ba3037;
      color: #ba3037;
      background: #ffffff;
      border-radius: 17px;
      margin-left: 7px;
      font-size: 18px;
      width: 102px;
      height: 34px;

      &:hover {
        background: linear-gradient(232deg, #b93037, #ef525b);
        box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
      }
    }

    .iconList {
      display: inline-block;
      width: 16px;
      height: 16px;
    }

    .set {
      background: url(../../assets/img/schoolIndex/Icon_Btn_SetBg.png) no-repeat;
      background-size: 100% 100%;
    }

    .transfer {
      background: url(../../assets/img/schoolIndex/Icon_Btn_ZrYz.png) no-repeat;
      background-size: 100% 100%;
    }

    .modify {
      background: url(../../assets/img/schoolIndex/Icon_Btn_ChaMm.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}

.inforTitle {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin: 20px 0;
  padding-left: 12px;
  position: relative;

  span {
    display: inline-block;
    width: 4px;
    height: 18px;
    background: #ba3037;
    border-radius: 2px;
    position: absolute;
    top: 7px;
    left: 0;
  }

  em {
    vertical-align: middle;
  }

  i {
    vertical-align: middle;
    margin-left: 15px;
  }

  .schoolmaster {
    padding: 3px 11px;
    height: 24px;
    border: 1px solid #ba3037;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #ba3037;
    font-style: normal;
  }

  .editor {
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    color: #36467b;
    position: absolute;
    top: 0;
    left: 1rem;
    cursor: pointer;

    b {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(../../assets/img/schoolIndex/Icon_Set_Edit.png) no-repeat;
      background-size: 100% 100%;
      margin-right: 10px;
    }
  }
}

.inforName {
  overflow: hidden;
  margin-right: 160px;

  .inforText {
    font-size: 16px;
    font-weight: 400;
    margin-right: 100px;
    margin-bottom: 20px;

    label {
      display: inline-block;
      color: #888;
      font-size: 16px;
      margin-right: 10px;
      line-height: 40px;
    }

    .color {
      color: #333;
    }

    p {
      display: inline-block;
      color: #333333;
      line-height: 40px;
    }
  }

  .leftPic {
    img {
      display: inline-block;
      margin-right: 30px;
      width: 100px;
      height: 80px;
      margin-bottom: 10px;
    }
  }
}

.uploadBtnClear {
  padding: 10px 32px;
  border: 1px solid #ba3037;
  //background: linear-gradient(232deg, #B93037, #EF525B);
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
  color: #ba3037;
  background: #fff;
  margin-top: 20px;
}

.active {
  background: url(../../assets/img/schoolIndex/Btn_Bg_Save_U.png) no-repeat top
    center;
  background-size: 100% 100%;
  width: 117px;
  height: 60px;
  border: none;
  color: #fff;
}

/deep/ .el-upload__tip {
  font-size: 14px;
  font-weight: 400;
  color: #ba3037;
  margin-top: 13px;
  margin-bottom: 20px;

  img {
    display: inline-block;
    width: 100px;
    height: 80px;
  }
}

.teachers {
  width: 420px;
  margin-bottom: 20px;
  margin-right: 70px;
  //height: 138px;
}
.teachers ::after {
  display: block;
  clear: both;
}

.radius {
  padding: 12px;
  box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
  border-radius: 2px;
  margin-right: 50px;
  position: relative;
}

.teachersHeader {
  width: 100px;
  height: 140px;
  margin-right: 20px;
  margin-bottom: 4px;
  float: left;
  background-position: 50%;
  background-size: cover;
  border-radius: 4px;
  -webkit-transform: translateZ(0);

  img {
    width: 100%;
    height: 100%;
  }
}

.picIcon {
  position: absolute;
  right: -6px;
  top: -5px;
  width: 16px;
  height: 16px;
  background: url(../../assets/img/schoolIndex/close.jpg) no-repeat;
  background-size: 100% 100%;
}

.teacherInfo {
  float: left;

  .tackList {
    overflow: hidden;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 30px;

    .name {
      //width: 200px;
      //height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .sex {
      //width: 70px;
      //height: 40px;
      margin-right: 20px;
    }

    .education {
      //width: 70px;
      //height: 40px;
    }
  }

  .intro {
    color: #888;
    font-size: 14px;

    p {
      //overflow: hidden;
      //text-overflow: ellipsis;
      //display: -webkit-box;
      //-webkit-box-orient: vertical;
      //-webkit-line-clamp: 2;
      width: 98px;
    }
  }
}

.teacherList {
  margin-bottom: 20px;
  overflow: hidden;

  label {
    display: block;
    color: #888;
    margin-bottom: 14px;
    font-size: 16px;
  }
}

.uploadElem {
  width: 100px;
  height: 138px;
  border: 1px dashed #dddddd;
  border-radius: 6px;
  text-align: center;

  .el-icon-plus {
    line-height: 138px;
  }
}

.widthPic {
  height: 80px;
  img {
    width: 100%;
    height: 80px;
  }
  .el-icon-plus {
    line-height: 80px;
  }
}

.inputWidth {
  display: inline-block;
  width: 368px;
}

/deep/ .el-input__inner {
  display: inline-block;
  width: 368px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  text-align: left;
  padding-left: 10px;
}

/deep/ .el-input__suffix,
.el-input__suffix-inner {
  line-height: 40px;
}

/deep/ .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}

.licensePic {
  border-radius: 4px;
  width: 80px;
  height: 80px;
  background: #dddddd;
  margin-top: 11px;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
  }

  p {
    padding-top: 12px;

    em {
      display: inline-block;
      width: 58px;
      height: 32px;
      background: url(../../assets/img/schoolIndex/Pic_ZzXk_Ks.png) no-repeat;
      background-size: 100% 100%;
    }

    span {
      display: block;
      font-size: 12px;
      font-weight: 400;
      color: #888888;
    }
  }
}
.goldcoin-content {
  padding-bottom: 30px;
  .goldcoin-content-title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
  }
  .goldcoin-content-count {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    padding: 20px 0 30px 0;
    span {
      font-size: 48px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ba3037;
      margin-right: 10px;
    }
  }
  .goldcoin-content-btn {
    display: flex;
    .recharge-btn {
      width: 100px;
      height: 38px;
      background: linear-gradient(232deg, #b93037, #ef525b);
      box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
      border-radius: 6px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 38px;
      cursor: pointer;
    }
    .purchase-btn {
      width: 100px;
      height: 38px;
      background: linear-gradient(232deg, #b93037, #ef525b);
      box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
      border-radius: 6px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin-left: 30px;
      text-align: center;
      line-height: 38px;
      cursor: pointer;
    }
  }
}
</style>
